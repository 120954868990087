// extracted by mini-css-extract-plugin
export var arrow = "index-module--arrow--y4vht";
export var arrowWrapper = "index-module--arrowWrapper--k--kZ";
export var bigTitle = "index-module--bigTitle--Ykx2f";
export var blue = "index-module--blue--8cZcq";
export var codeBlock = "index-module--codeBlock--Ea6qT";
export var command = "index-module--command--TvWKD";
export var description = "index-module--description--FAV7T";
export var disclaimer = "index-module--disclaimer--KexC4";
export var documentation = "index-module--documentation--MIYX+";
export var example = "index-module--example--e3pTR";
export var exampleContainer = "index-module--exampleContainer--adqQO";
export var features = "index-module--features--M2Guk";
export var getStarted = "index-module--getStarted--OdNCl";
export var illustration = "index-module--illustration--nTQjr";
export var left = "index-module--left--C7WY9";
export var leftSide = "index-module--leftSide--AZmT0";
export var line = "index-module--line--peQS7";
export var lineBlue = "index-module--lineBlue--dyxKQ";
export var lineRed = "index-module--lineRed--lIaBs";
export var logo = "index-module--logo--TkjgK";
export var logoWrapper = "index-module--logoWrapper--N7+VH";
export var logos = "index-module--logos--VZo8Y";
export var main = "index-module--main--R-DjA";
export var menuOver = "index-module--menuOver--rVDyI";
export var over = "index-module--over--Ntc9j";
export var parameterBased = "index-module--parameterBased--sN1Ca";
export var pictures = "index-module--pictures--L3lvT";
export var picturesMobile = "index-module--picturesMobile--nCFZs";
export var presets = "index-module--presets--hT0OC";
export var red = "index-module--red--ZW9ZZ";
export var redText = "index-module--redText--yfDo-";
export var right = "index-module--right--ow4lc";
export var rightSide = "index-module--rightSide--l5vCm";
export var root = "index-module--root--Ao+kK";
export var rotLine = "index-module--rotLine--xZSHj";
export var rotatingLine = "index-module--rotatingLine--tdYiU";
export var row = "index-module--row--WCP+N";
export var startButton = "index-module--startButton--kSFpy";
export var subscribe = "index-module--subscribe--SXT2A";
export var text = "index-module--text--yaj90";
export var tooltip = "index-module--tooltip--nqJXc";
export var tooltipCode = "index-module--tooltipCode--i7Zhd";
export var tooltipExport = "index-module--tooltipExport--Jp-Dz";
export var tooltipLive = "index-module--tooltipLive--ATGgg";
export var twoColumns = "index-module--twoColumns--MEas-";
export var under = "index-module--under--0qqtp";
export var upperText = "index-module--upperText--4BhYw";
export var video = "index-module--video--nyyBg";
export var webTech = "index-module--webTech--wWa4Y";