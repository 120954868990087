import React, { memo } from "react";
import classnames from "classnames";

import * as css from "./Video.module.css";

export const Video = memo(({ src, className }) => {
  return (
    <figure className={classnames(css.root, className)}>
      <video autoPlay muted playsInline loop poster={`${src}.jpg`}>
        <source src={`${src}.webm`} type={`video/webm`} />
        <source src={`${src}.mp4`} type={`video/mp4`} />
      </video>
    </figure>
  );
});
export default Video;
