import React, { useState, useEffect } from "react";

import * as css from "./SuccessPopup.module.css";

export const SuccessPopup = ({ setSubscribed }) => {
  const [open, setOpen] = useState();
  useEffect(() => {
    if (window.location.search.includes("success")) {
      setOpen(true);
      setSubscribed(true);
    }
  }, [setSubscribed]);

  const onClose = () => {
    setOpen(false);
    window.history.replaceState({}, document.title, "/");
  };

  return open ? (
    <div className={css.root}>
      <h2 className={css.title}>ALMOST DONE...</h2>

      <p className={css.cta}>
        Please check your email and <em>click</em> on the link we sent you to
        confirm your subscription.
      </p>
      <p>Thanks for subscribing! We'll be in touch very soon.</p>
      <button className={css.close} onClick={onClose}>
        close
      </button>
    </div>
  ) : null;
};

export default SuccessPopup;
