// extracted by mini-css-extract-plugin
export var blueBlock = "Hero-module--blueBlock--0Nd4Y";
export var description = "Hero-module--description--VLDJg";
export var designTools = "Hero-module--designTools--rAHAI";
export var dsiLogo = "Hero-module--dsiLogo--EDtWx";
export var feature = "Hero-module--feature--vFWbo";
export var heroText = "Hero-module--heroText--tQfbD";
export var label = "Hero-module--label--nWhT+";
export var logoWrapper = "Hero-module--logoWrapper--GZpmc";
export var movingText = "Hero-module--movingText--QAjQ6";
export var root = "Hero-module--root--BABZw";
export var startButton = "Hero-module--startButton--AMz-Z";
export var text = "Hero-module--text--kDT-Q";
export var twoColumns = "Hero-module--twoColumns--zfKiI";
export var upperText = "Hero-module--upperText--mHFOS";