import React from "react";
import classnames from "classnames";

import CopyButton from "./CopyButton";

import * as css from "./ShortCode.module.css";

export const ShortCode = ({ children, className, copyText }) => {
  return (
    <div className={classnames(css.root, className)}>
      <pre className={css.code}>
        <code>{children}</code>
      </pre>
      {copyText && <CopyButton text={copyText} className={css.copy} />}
    </div>
  );
};

export default ShortCode;
