import React from "react";
import classnames from "classnames";

import * as css from "./Code.module.css";

export const Code = ({ variant, children, className }) => {
  return (
    <div
      className={classnames(css.root, className, {
        [css[variant]]: css[variant]
      })}>
      <pre>
        <code>{children}</code>
      </pre>
    </div>
  );
};

export default Code;
