import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { animated } from "@react-spring/web";

import Layout from "../components/Layout";
import MainMenu from "../components/common/MainMenu";
import Footer from "../components/common/Footer";
import Subscribe from "../components/common/Subscribe";
import Code from "../components/common/Code";
import ShortCode from "../components/common/ShortCode";
import SuccessPopup from "../components/common/SuccessPopup";
import Button from "../components/common/Button";
import Logo from "../components/common/Logo";
import RotatingLine from "../components/common/RotatingLine";
import Video from "../components/Video";
import Hero from "../components/Hero";

import { useScrollProgress, useMenuOffset } from "../utils/hooks";

// import faviconGif from "../images/favicon.gif";
// import favicon16 from "../images/favicon_16.png";
// import favicon32 from "../images/favicon_32.png";
// import favicon64 from "../images/favicon_64.png";
// import favicon144 from "../images/favicon_144.png";

import { useHighlight } from "../utils/hooks";

import * as grid from "../style/grid.module.css";
import * as css from "../style/index.module.css";

import P5jsLogo from "../images/p5js.svg";
import ReactLogo from "../images/react_logo.svg";
import HTML5Logo from "../images/html5_logo.svg";
import Arrow from "../images/arrow.svg";

const IndexPage = ({ data }) => {
  const menuHeight = 70;
  const [offset, heroRef] = useMenuOffset({ height: menuHeight });
  const progress = useScrollProgress();
  useHighlight();

  const [subscribed, setSubscribed] = useState();
  const iframe = useRef();

  useEffect(() => {
    const iframeRef = iframe.current;
    const onLoad = () => {
      const root = iframeRef.contentWindow.document.getElementById("root");
      if (root) {
        root.setAttribute("style", "color:var(--mechanic-red);");
      }
    };
    iframeRef?.addEventListener?.("load", onLoad);
    return () => {
      iframeRef?.removeEventListener?.("load", onLoad);
    };
  }, []);

  return (
    <Layout title="Mechanic" className={css.root}>
      <animated.div
        style={{
          display: offset.to(o => (o < menuHeight ? "block" : "none"))
        }}>
        <MainMenu
          title={"Mechanic"}
          color={"transparent"}
          progress={progress}
        />
      </animated.div>

      <animated.div
        className={css.menuOver}
        style={{
          height: menuHeight,
          pointerEvents: offset.to(o => (o >= menuHeight ? "initial" : "none")),
          clipPath: offset.to(o =>
            o >= menuHeight
              ? "none"
              : `polygon(
            0 ${menuHeight - o}px,
            0 ${menuHeight}px,
            100% ${menuHeight}px,
            100% ${menuHeight - o}px)`
          )
        }}>
        <MainMenu title={"Mechanic"} color={"red"} progress={progress} />
      </animated.div>

      <main className={css.main}>
        <div ref={heroRef}>
          <Hero />
        </div>
        <div className={classnames(grid.grid, css.features, css.lineBlue)}>
          <div className={classnames(css.twoColumns, css.left, css.row)}>
            <div className={css.description}>
              Current design tools are still modeled on manual processes which
              despite technological advances continue to demand repetitive work
              from designers.
            </div>
            <div className={css.rotatingLine}>
              <RotatingLine size={120} progress={progress} color="red" />
            </div>
            <div className={css.illustration}>
              <Video src={"/videos/export-cycle.500"} />
            </div>
          </div>

          <div className={classnames(css.twoColumns, css.right, css.row)}>
            <div className={css.illustration}>
              <Video src={"/videos/code.900"} />
            </div>
            <div className={css.rotatingLine}>
              <RotatingLine size={120} progress={progress} color="blue" />
            </div>
            <div className={css.description}>
              Mechanic allows you to automate this endless cycle by encoding
              your decisions into design functions.
            </div>
          </div>

          <div className={classnames(css.twoColumns, css.left, css.row)}>
            <div className={css.description}>
              Mechanic will auto-generate a beautiful user interface, so you can
              concentrate on writing the code to produce your designs.
              <br />
              <br />
              This makes it excellent at making tools for anything that requires
              a high volume of rich, consistent assets from business cards to
              highly flexible visual systems.
            </div>
            <div className={css.rotatingLine}>
              <RotatingLine size={120} progress={progress} color="red" />
            </div>
            <div className={css.illustration}>
              <Video src={"/videos/business-cards.1080"} />
            </div>
          </div>

          <div className={classnames(css.twoColumns, css.right, css.row)}>
            <div className={css.illustration}>
              <Logo width={301} />
            </div>
            <div className={css.rotatingLine}>
              <RotatingLine size={120} progress={progress} color="blue" />
            </div>
            <div className={css.description}>
              Adding Mechanic to your toolkit enables you to turn your ideas
              into actionable tools and turbocharge your design process.
            </div>
          </div>
        </div>
        <div className={classnames(grid.grid, css.lineRed, css.parameterBased)}>
          <div className={css.leftSide}>
            <p className={css.upperText}>
              Mechanic makes it fast and easy to make
              project&#8288;&#8211;&#8288;specific design&nbsp;tools
            </p>

            <Code variant="blue" className={css.codeBlock}>
              {`export const handler = (sketch, params, mechanic) => {
  const { width, height } = params;

  let x = 0;
  const y = height / 2;
  ...
}`}
            </Code>
          </div>
          <div className={css.arrowWrapper}>
            <Arrow className={css.arrow} />
          </div>
          <div className={css.rightSide}>
            <Video src={"/videos/poster-maker.1080"} />
          </div>

          <Tooltip
            text={"write a single function that generates your design inputs"}
            color={"blue"}
            className={css.tooltipCode}
          />
          <Tooltip
            text={"export images and videos as SVG, PNG and WEBM"}
            color={"red"}
            className={css.tooltipExport}
          />
          <Tooltip
            text={"preview your live designs in the canvas area"}
            color={"red"}
            className={css.tooltipLive}
          />
        </div>
        <div className={css.webTech}>Using familiar web technologies</div>
        <div className={classnames(css.logos, css.lineRed)}>
          <P5jsLogo className={css.logo} width={170} height={null} />
          <ReactLogo className={css.logo} width={190} height={null} />
          <HTML5Logo className={css.logo} width={150} height={null} />
          <p>Others coming soon...</p>
        </div>

        <h1 className={css.bigTitle}>Go on, give it a try</h1>

        <div className={classnames(css.exampleContainer, grid.grid)}>
          <iframe
            className={css.example}
            title={`Design function document.`}
            src={`example/`}
            ref={iframe}></iframe>
        </div>
        <div className={css.getStarted} id="get-started">
          <div className={css.text}>
            <h2>Run this to&nbsp;start a&nbsp;new project</h2>
            <ShortCode
              className={css.command}
              copyText="npm init mechanic@latest my-project">
              npm init mechanic@latest my-project
            </ShortCode>
            <div className={classnames(css.twoColumns, css.documentation)}>
              <div className={css.line}>
                Mechanic’s open source command-line client makes it easy to
                quickly get started making your own personalized web-based
                design tool that will allow you to generate assets right in your
                browser.
              </div>
              <div className={css.line}>
                <p>
                  Please visit our <a href="/docs/v1.2.0">documentation</a> for
                  a thorough walk-through of the ins and outs of using the
                  Mechanic API.
                </p>

                <Button
                  variant={"blue"}
                  className={css.startButton}
                  href="/docs/v1.2.0">
                  Go to documentation
                </Button>
              </div>
            </div>
          </div>

          <div className={css.pictures}>
            <GatsbyImage
              image={getImage(data.docs2)}
              alt=""
              className={css.under}
            />
            <GatsbyImage
              image={getImage(data.docs1)}
              alt=""
              className={css.over}
            />
          </div>

          <div className={css.picturesMobile}>
            <GatsbyImage
              image={getImage(data.docs_mobile_1)}
              alt=""
              className={css.under}
            />
            <GatsbyImage
              image={getImage(data.docs_mobile_2)}
              alt=""
              className={css.over}
            />
          </div>
        </div>
        <div className={grid.grid}>
          <Subscribe
            className={classnames(css.subscribe, grid.col)}
            variant={"red"}
          />
        </div>
      </main>
      <Footer subscribed={subscribed} variant={"red"} />
      <SuccessPopup setSubscribed={setSubscribed} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    docs1: file(name: { eq: "docs1" }) {
      childImageSharp {
        gatsbyImageData(width: 1500)
      }
    }
    docs2: file(name: { eq: "docs2" }) {
      childImageSharp {
        gatsbyImageData(width: 1500)
      }
    }
    docs_mobile_1: file(name: { eq: "docs_mobile_1" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    docs_mobile_2: file(name: { eq: "docs_mobile_2" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
  }
`;

const Tooltip = ({ text, color, className }) => {
  return (
    <div
      className={classnames(css.tooltip, className, {
        [css[color]]: css[color]
      })}>
      {text}
    </div>
  );
};
