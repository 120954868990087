import React from "react";

import Button from "../components/common/Button";
import Logo from "../components/common/Logo";

import DSILogo from "../images/dsi_logo.svg";

import * as grid from "../style/grid.module.css";
import * as css from "./Hero.module.css";

const Hero = () => {
  return (
    <div className={css.root}>
      <div className={css.blueBlock}>
        <p className={css.heroText}>
          Turn your design&nbsp;rules{" "}
          <span className={css.designTools}>into design tools</span>
        </p>
      </div>
      <div className={css.logoWrapper}>
        <Logo />
      </div>
      <div className={grid.grid}>
        <div className={css.feature}>
          <div className={css.twoColumns}>
            <div className={css.description}>
              Mechanic is an open source framework that makes it easy to create
              custom, web-based design tools that export design assets right in
              your browser.
            </div>
            <Button
              variant="white"
              size="large"
              className={css.startButton}
              href="#get-started">
              <span>Sounds good?</span> Get started!
            </Button>
          </div>
        </div>
      </div>
      <div className={css.label}>
        Built by{" "}
        <a href={"https://designsystems.international/"}>
          <DSILogo className={css.dsiLogo} />
        </a>
      </div>
    </div>
  );
};

export default Hero;
